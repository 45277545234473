@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.__react_component_tooltip.type-dark.place-top.campaign__row_one__column_one__item__tooltipColor:after {
  border-top-color: #62618B;
}

.__react_component_tooltip.type-dark.place-top.campaign__row_one__column_one__item__tooltipColor.tooltipColor_note:after {
  left: 89%;
}
.__react_component_tooltip.place-top.campaign__row_one__column_one__item__tooltipColor:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -6px;
  margin-left: -8px;
}

.row-aux-value {
  margin-top: 0px;
}
.row-aux-value2 {
  margin-top: 0px;
}
.row-aux-value3 {
  margin: 16px 0px 0px !important;
}

.text-aux-value {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.label-aux-value {
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

@media (max-width: $medium-smartphone) {
  .row-aux-value {
    margin-top: 10px !important;
  }
  .row-aux-value2 {
    margin-top: 25px !important;
  }
}

.li-items {
  width: calc(100% - 24px);
  margin: 12px;
}

.margin_span_tooltip {
  display: block;
  margin-left: 0.7em;
  margin-top: -18px;
}

.contidions {
  position: static;
  height: auto;
  left: 570px;
  top: 0px;
  background: #ffffff;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 30px 30px;

  .campaign {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px 20px;
    position: static;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    @media (max-width: 401px) {
      padding: 0px 20px calc(var(--heiht-screen-mobil) + 20px);
    }

    &__label {
      position: static;
      width: 97.5%;
      height: 33px;
      left: 32px;
      top: 24px;
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #475061;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin: 24px 0px 16px;
    }

    &__row_one {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      position: static;
      width: 100%;
      left: 32px;
      top: 81px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: 0px 0px;

      &__column_one {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 50%;
        height: 100%;
        left: 0px;
        top: 0px;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0px 0px;

        &__label {
          position: static;
          width: 100%;
          left: 0px;
          top: 0px;
          font-family: Nunito;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #475061;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin: 0px 0px;
        }

        &__item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          position: static;
          width: 100%;
          height: 26px;
          left: 0px;
          top: 19px;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          margin: 0px 0px;

          &__text {
            position: static;
            left: 0px;
            top: 0px;
            font-family: Nunito;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #475061;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0px 0px;
            height: 24px;
            &__bottomtext {
              flex: none;
              height: 24px;

              font-family: Nunito;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: #475061;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0px 0px;
              height: 24px;
              @media (max-width: $medium-smartphone) {
                width: var(--width-screen);
              }
            }
          }

          &__icon {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' transform='matrix(-1 0 0 1 20 0)' fill='%23475061'/%3E%3Ccircle cx='10' cy='6' r='1.5' fill='white'/%3E%3Crect x='8.5' y='8.5' width='3' height='7' rx='1.5' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            position: static;
            width: 20px;
            height: 20px;
            left: 48px;
            top: 3px;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 0px 8px;
            @media (max-width: $medium-smartphone) {
              margin: 0px 3px;
            }

            &__Convertible__Note {
              background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' transform='matrix(-1 0 0 1 20 0)' fill='%23475061'/%3E%3Ccircle cx='10' cy='6' r='1.5' fill='white'/%3E%3Crect x='8.5' y='8.5' width='3' height='7' rx='1.5' fill='white'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center center;
              position: relative;
              width: 20px;
              height: 20px;
              left: calc(100% - 40px);
              top: -215px;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 0px 8px;
            }
          }

          &__tooltipColor {
            width: 310px;
            background-color: #62618B !important;
            opacity: 1 !important;
            border-radius: 8px;
            padding: 0px;
          }

          &__label {
            font-family: Nunito;
            font-style: normal;
            font-size: 14px;
            font-weight: 700;
            line-height: 19px;
            align-items: center;
            text-align: left;
            color: #f9f9f9;
            width: calc(100% - 24px);
            margin: 12px;
          }

          &__classification {
            opacity: 1 !important;
            background-color: #62618B !important;
            border-radius: 8px;
          }

          &__container {
            width: 100%;
            display: flex;
            flex-direction: column;
          }

          &__text {
            &--title {
              color: white;
              font-family: Nunito;
              font-weight: 700;
              font-size: 14px !important;
              margin-left: 0px;
              line-height: 16px !important;
              margin-bottom: 5px;
            }
            &--subtitle {
              color: white;
              font-family: Nunito;
              font-size: 14px !important;
              font-weight: 700;
              margin-left: 0px;
              margin-right: 0x;
              line-height: 16px !important;
            }
            &--item {
              color: white;
              font-family: Nunito;
              font-size: 14px !important;
              font-weight: 700;
              margin: 2px -10px 2px 0px !important;
              line-height: 16px !important;
              list-style-type: none;

              &__li {
                font-weight: 700;
                padding-left: 0em;
                margin-top: 13px;
                margin-left: 10px;
                margin-right: 12px;
                counter-increment: customlistcounter;
              }
              &__li:before {
                content: counter(customlistcounter) " ";
                margin-left: -10px;
              }

              &__cap {
                &__1,
                &__2 {
                  margin-top: 13px;
                  padding-left: 5px;
                }
              }
            }
          }
        }
        &__item_sector {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          position: static;
          width: 100%;
          left: 0px;
          top: 19px;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          margin: 0px 0px;

          &__text_sector {
            width: 105%;
            position: static;
            left: 0px;
            top: 0px;
            font-family: Nunito;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            color: #475061;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0px 0px;
            @media (max-width: $smartphone) {
              width: 100%;
            }
          }
        }
      }

      .column-one_flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        height: 45px;
        left: 0px;
        top: 0px;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0px 0px;
      }

      .column-two_flex {
        display: block;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        //height: 45px;
        top: 0px;
        flex: none;
        order: 1;
        flex-grow: 1;
        margin: 8px 0px 0px;
      }

      .column-two {
        display: block;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 50%;
        height: 100%;
        left: 50%;
        top: 0px;
        flex: none;
        order: 1;
        flex-grow: 1;
        margin: 0px 30px 0px 10px !important;
        @media (max-width: $medium-smartphone) {
          margin: 0px 30px 0px 15px !important;
        }
      }
    }

    .row-two {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      position: static;
      width: 100%;
      left: 32px;
      top: 150px;
      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;
      margin: 8px 0px;
      &__Shares {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        height: auto;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 8px 0px 12px;
      }
      &__Debt {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 24px 0px;
      }
    }

    .row-three {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      position: static;
      width: 100%;
      left: 32px;
      top: 219px;
      flex: none;
      order: 3;
      align-self: stretch;
      flex-grow: 0;
      margin: 0px 0px;
      &__Convertible__Note {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 16px 0px;
        position: static;
        width: auto;
        height: 144px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px -16px;
        background: #f2f2f2;
        border-radius: 8px;
      }
      &__Shares {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        height: 32px;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 14px 0px;
      }
      &__Debt {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px;
      }
    }

    .row-four {
      display: block;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      position: static;
      width: 100%;
      height: 100%;
      left: 32px;
      top: 150px;
      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;
      margin: 14px 0px 0px;
      &__Convertible__Note {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        left: 32px;
        top: 81px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 32px 0px 0px;
      }
      &__Shares {
        display: block;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        height: 100%;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 14px 0px 0px;
      }
      &__Debt {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 24px 0px;
      }
    }

    .row-five {
      display: block;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      position: static;
      width: 100%;
      height: 100%;
      left: 32px;
      top: 150px;
      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;
      margin: 8px 0px 0px;
      &__Shares {
        display: block;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        height: 100%;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 8px 0px 0px;
      }
      &__Debt {
        display: block;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px 24px;
      }
    }
  }

  .campaign_note {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 32px;
    position: static;
    height: auto;
    left: 0px;
    top: 0px;
    border-radius: 8px;
  }
  .campaign_debt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 32px;
    position: static;
    height: auto;
    left: 0px;
    top: 0px;
    border-radius: 8px;
  }
}

@media (max-width: $responsive-desktop) {
  .__react_component_tooltip.campaign__row_one__column_one__item__tooltipColor {
    left: calc(100% - 360px) !important;
    right: 8% !important;
  }
  .__react_component_tooltip.place-top.campaign__row_one__column_one__item__tooltipColor:after {
    left: var(--mouse-x);
  }
}

@media (min-width: $desktop) {
  .__react_component_tooltip.type-dark.place-top.campaign__row_one__column_one__item__tooltipColor:after {
    border-top-color: #62618B;
    left: 50%;
  }

  .__react_component_tooltip.type-dark.place-top.campaign__row_one__column_one__item__tooltipColor.tooltipColor_note:after {
    left: 50%;
  }

  .display-flex{
    display: flex;
  }

  .row-aux-value3 {
    margin: 16px 0px !important;
  }

  .tooltipColor_note {
    width: 28% !important;
  }

  .text-aux-value {
    width: auto !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .label-aux-value {
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .contidions {
    margin: 25px auto;
    height: calc(var(--heiht-screen) + 400px);
    .campaign {
      &__label {
        margin: 10px 0px 12px 0px;
      }
      &__row_one {
        display: flex;
        flex-direction: row;
        width: 100%;
        left: 32px;
        top: 81px;
        order: 1;
        margin: 0px 0px;

        &__column_one {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          position: static;
          width: 45.5%;
          height: 45px;
          left: 0px;
          top: 0px;
          flex: none;
          order: 0;
          margin: 0px 0px;
          &__item {
            &__icon {
              &__Convertible__Note {
                left: calc(100% - 40px);;
                top: -148px;
                margin: 0px 8px;
              }
            }
            &__tooltipColor {
              width: 360px;
              background-color: #62618B !important;
              opacity: 1 !important;
              border-radius: 8px;
              padding-left: 0px;
              padding-right: 0px;
            }
            &__container {
              width: 100%;
              display: flex;
              flex-direction: column;
              background-color: #62618B;
              margin: 0px !important;
            }
          }
        }

        .column-two {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          position: static;
          height: 45px;
          left: 253px;
          top: 0px;
          flex: none;
          order: 1;
          margin: 0px 30px;
        }
        .column-one_flex {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          position: static;
          width: 45.5%;
          height: 45px;
          left: 0px;
          top: 0px;
          flex: none;
          order: 0;
          margin: 0px 0px;
        }

        .column-two_flex {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          position: static;
          width: 50%;
          height: 45px;
          left: 253px;
          top: 0px;
          flex: none;
          order: 1;
          margin: 0px 10px;
        }
      }

      .row-two {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        height: 32px;
        left: 32px;
        top: 150px;
        flex: none;
        order: 2;
        margin: 24px 0px;
        &__Shares {
          display: flex;
          height: 32px;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          position: static;
          left: 32px;
          top: 150px;
          flex: none;
          order: 2;
          margin: 24px 0px;
        }
        &__Debt {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          position: static;
          left: 32px;
          top: 150px;
          flex: none;
          order: 2;
          margin: 24px 0px;
        }
      }

      .row-three {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 476px;
        height: 45px;
        left: 32px;
        top: 219px;
        flex: none;
        order: 3;
        margin: 0px 0px;
        &__Convertible__Note {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          position: static;
          height: 144px;
          left: 22px;
          top: 206px;
          flex: none;
          order: 3;
          margin: 0px -16px;
          background: #f2f2f3;
          border-radius: 8px;
        }
        &__Debt {
          margin: 0px;
        }
      }
      .row-four {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        height: 45px;
        left: 32px;
        top: 288px;
        flex: none;
        order: 4;
        margin: 18px 0px;
        &__Convertible__Note {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          position: static;
          height: 112px;
          left: 32px;
          top: 288px;
          flex: none;
          order: 4;
          margin: 32px 0px 0px;
        }
        &__Shares {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          position: static;
          height: 32px;
          left: 32px;
          top: 150px;
          flex: none;
          order: 2;
          margin: 24px 0px;
        }
        &__Debt {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          position: static;
          left: 32px;
          top: 150px;
          flex: none;
          order: 2;
          margin: 24px 0px;
        }
      }

      .row-five {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 476px;
        height: 45px;
        left: 32px;
        top: 357px;
        flex: none;
        order: 5;
        margin: 0px 0px;
        &__Shares {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          position: static;
          height: 32px;
          left: 32px;
          top: 150px;
          flex: none;
          order: 2;
          margin: 14px 0px;
        }
        &__Debt {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          position: static;
          left: 32px;
          top: 150px;
          flex: none;
          order: 2;
          margin: 0px 0px 10px;
        }
      }
    }
  }
}
